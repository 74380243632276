import React, { useState, useEffect, ChangeEvent } from 'react';
import { DateSelectorProps } from '../../types/props';

type TimePeriod = 'custom' | 'month' | 'quarter' | 'year';

const DateSelector: React.FC<DateSelectorProps> = ({ onDateChosen }) => {
  const getCurrentQuarter = (): number => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return Math.floor(currentMonth / 3) + 1;
  };

  const [timePeriod, setTimePeriod] = useState<TimePeriod>('quarter');
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(1);
  const [quarter, setQuarter] = useState<number>(getCurrentQuarter());
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    handleApply();
  }, [timePeriod, year, month, quarter]);

  const handleApply = () => {
    let start: string;
    let end: string;
    let period: 'custom' | 'month' | 'quarter' | 'year';
    let dateSelectionString: string;

    if (timePeriod === 'custom' && startDate && endDate) {
      start = startDate;
      end = endDate;
      period = 'custom';
      dateSelectionString = `${startDate} to ${endDate}`;
    } else if (timePeriod === 'month') {
      start = year.toString();
      end = month.toString();
      period = 'month';
      dateSelectionString = `${year}-${month.toString().padStart(2, '0')}`;
    } else if (timePeriod === 'quarter') {
      start = year.toString();
      end = quarter.toString();
      period = 'quarter';
      dateSelectionString = `${year} Q${quarter}`;
    } else {
      start = year.toString();
      end = '';
      period = 'year';
      dateSelectionString = `${year}`;
    }

    onDateChosen({ start, end, period, dateSelectionString });
  };

  const generateYearOptions = (): number[] => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push(i);
    }
    return years;
  };

  return (
    <div className="mb-4">
      <div className="flex space-x-4">
        <select
          value={timePeriod}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => setTimePeriod(e.target.value as TimePeriod)}
          className="border rounded px-2 py-1"
        >
          <option value="custom">Custom</option>
          <option value="month">Month</option>
          <option value="quarter">Quarter</option>
          <option value="year">Year</option>
        </select>
        
        {timePeriod !== 'custom' && (
          <select
            value={year}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setYear(parseInt(e.target.value))}
            className="border rounded px-2 py-1"
          >
            {generateYearOptions().map((y) => (
              <option key={y} value={y}>{y}</option>
            ))}
          </select>
        )}

        {timePeriod === 'month' && (
          <select
            value={month}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setMonth(parseInt(e.target.value))}
            className="border rounded px-2 py-1"
          >
            {[...Array(12)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        )}

        {timePeriod === 'quarter' && (
          <select
            value={quarter}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setQuarter(parseInt(e.target.value))}
            className="border rounded px-2 py-1"
          >
            {[1, 2, 3, 4].map((q) => (
              <option key={q} value={q}>Q{q}</option>
            ))}
          </select>
        )}

        {timePeriod === 'custom' && (
          <>
            <input
              type="date"
              value={startDate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <button 
              onClick={handleApply} 
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Apply
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DateSelector;