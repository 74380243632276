import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileUpload from './FileUpload';
import Toast from '../common/Toast';
import { Document } from '../../types';
import { useUser } from '../../contexts/UserContext';
import { useOrganization, ORG_CONTEXT_CHANGED_EVENT } from '../../contexts/OrganizationContext';

function Documents() {
  const [personalDocuments, setPersonalDocuments] = useState<Document[]>([]);
  const [sharedWithMeDocuments, setSharedWithMeDocuments] = useState<Document[]>([]);
  const [sharedByMeDocuments, setSharedByMeDocuments] = useState<Document[]>([]);
  const [error, setError] = useState('');
  const [toast, setToast] = useState({ message: '', type: '' });
  const { id: currentUserId, loading: userLoading } = useUser();
  const { currentContext } = useOrganization();

  const fetchDocuments = async () => {
    try {
      const response = await axios.get('/api/user/documents');
      
      // Split documents into categories
      const personal: Document[] = [];
      const sharedWithMe: Document[] = [];
      const sharedByMe: Document[] = [];

      response.data.documents.forEach((doc: Document) => {
        if (doc.user_id === currentUserId && doc.uploaded_by_user_id === currentUserId) {
          // Documents uploaded by me for myself (personal or org-shared)
          personal.push(doc);
        } else if (doc.user_id === currentUserId) {
          // Documents uploaded by others for me
          sharedWithMe.push(doc);
        } else if (doc.uploaded_by_user_id === currentUserId) {
          // Documents I uploaded for others/departments/org
          sharedByMe.push(doc);
        }
      });

      setPersonalDocuments(personal);
      setSharedWithMeDocuments(sharedWithMe);
      setSharedByMeDocuments(sharedByMe);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to fetch documents');
    }
  };

  useEffect(() => {
    if (!userLoading) {
      fetchDocuments();
    }
  }, [userLoading]);

  // Listen for organization context changes
  useEffect(() => {
    const handleOrgContextChanged = () => {
      fetchDocuments();
    };

    window.addEventListener(ORG_CONTEXT_CHANGED_EVENT, handleOrgContextChanged);

    return () => {
      window.removeEventListener(ORG_CONTEXT_CHANGED_EVENT, handleOrgContextChanged);
    };
  }, []);

  const handlePermissionChange = async (documentId: string, newPermission: string) => {
    try {
      await axios.patch(`/api/user/documents/${documentId}/permission`, {
        permission_type: newPermission
      });
      fetchDocuments();
      setToast({ message: 'Document permission updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating document permission:', error);
      setToast({ message: 'Failed to update document permission', type: 'error' });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`/api/user/documents/${id}`);
      fetchDocuments();
      setToast({ message: 'Document deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting document:', error);
      setToast({ message: 'Failed to delete document', type: 'error' });
    }
  };

  const handleUploadSuccess = () => {
    fetchDocuments();
    setToast({ message: 'File uploaded successfully', type: 'success' });
  };

  const DocumentList = ({ documents, isSharedWithMe = false }) => (
    <ul className="bg-white shadow-md rounded-lg divide-y divide-gray-200">
      {documents.map((doc: Document) => (
        <li key={doc.id} className="flex justify-between items-center p-4 hover:bg-gray-50">
          <div className="flex-grow">
            <span className="font-medium">{doc.file_name}</span>
            <span className="ml-2 text-sm text-gray-500">({doc.file_type})</span>
            
            {/* Show who uploaded the document if shared with me */}
            {isSharedWithMe && doc.uploaded_by_name && (
              <span className="ml-2 text-xs text-gray-500">
                Uploaded by: {doc.uploaded_by_name}
              </span>
            )}

            {/* Show sharing information */}
            {doc.permission_type === 'organization' && (
              <span className="ml-2 text-xs text-gray-500">
                {doc.department_name 
                  ? `Shared with: ${doc.department_name} Department`
                  : 'Shared with: Entire Organization'}
              </span>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {currentContext.organization_id && !isSharedWithMe && (
              <select
                value={doc.permission_type}
                onChange={(e) => handlePermissionChange(doc.id, e.target.value)}
                className="border rounded px-2 py-1 text-sm bg-white focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="private">Private</option>
                <option value="organization">Organization</option>
              </select>
            )}
            {!isSharedWithMe && (
              <button
                onClick={() => handleDelete(doc.id)}
                className="px-3 py-1 rounded text-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Delete
              </button>
            )}
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Document Management</h1>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Upload New Document</h2>
        <div className="bg-gray-100 p-6 rounded-lg">
          <FileUpload onUploadSuccess={handleUploadSuccess} setToast={setToast} />
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">My Documents</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {personalDocuments.length === 0 ? (
          <p className="text-gray-600">No personal documents uploaded yet.</p>
        ) : (
          <DocumentList documents={personalDocuments} />
        )}
      </section>

      {sharedWithMeDocuments.length > 0 && (
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Documents Shared With Me</h2>
          <DocumentList documents={sharedWithMeDocuments} isSharedWithMe={true} />
        </section>
      )}

      {sharedByMeDocuments.length > 0 && (
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Documents I've Shared</h2>
          <DocumentList documents={sharedByMeDocuments} />
        </section>
      )}

      {toast.message && (
        <Toast 
          message={toast.message} 
          type={toast.type} 
          onClose={() => setToast({ message: '', type: '' })} 
        />
      )}
    </div>
  );
}

export default Documents;