import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideNav from './SideNav';
import { useUser } from '../../contexts/UserContext';
import { useOrganization } from '../../contexts/OrganizationContext';

function Layout({ children }) {
  const { id: currentUserId } = useUser();
  const { currentContext, organizations, setOrganizationContext, isAdmin, isManager } = useOrganization();
  const isLoggedIn = currentUserId != null;
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  const handleAccountChange = async (value) => {
    const orgId = value === 'individual' ? null : value;
    await setOrganizationContext(orgId);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {isLoggedIn && (
        <div className={`fixed top-0 ${isNavCollapsed ? 'left-16' : 'left-64'} right-0 bg-white border-b border-gray-200 z-10`}>
          <div className="px-4 py-2">
            <select
              value={currentContext.organization_id || 'individual'}
              onChange={(e) => handleAccountChange(e.target.value)}
              className="block w-48 px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="individual">Individual Account</option>
              {organizations.map(org => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
            {currentContext.organization_id && currentContext.department_name && (
              <div className="mt-1 text-sm text-gray-500">
                Department: {currentContext.department_name}
              </div>
            )}
          </div>
        </div>
      )}
      
      <SideNav 
        isCollapsed={isNavCollapsed}
        setIsCollapsed={setIsNavCollapsed}
        showLifeROI={!currentContext.organization_id}
        showDepartments={currentContext.organization_id && (isAdmin || isManager)}
      />
      
      <main className={`${isNavCollapsed ? 'ml-16' : 'ml-64'} ${isLoggedIn ? 'pt-16' : ''} transition-all duration-300`}>
        <div className="px-6 py-8">
          {children}
        </div>
      </main>
    </div>
  );
}

export default Layout; 