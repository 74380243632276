import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { UserContextData, UserProviderProps } from '../types/contexts';

const initialUserData: UserContextData = {
  id: null,
  name: null,
  email: null,
  loading: true,
  error: null
};

const UserContext = createContext<UserContextData>(initialUserData);

export function UserProvider({ children }: UserProviderProps): JSX.Element {
  const [userData, setUserData] = useState<UserContextData>(initialUserData);

  useEffect(() => {
    const fetchUserData = async (): Promise<void> => {
      try {
        const response = await axios.get<{
          id: string;
          name: string;
          email: string;
        }>('/api/user/current');
        
        setUserData({
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          loading: false,
          error: null
        });
      } catch (error) {
        setUserData({
          id: null,
          name: null,
          email: null,
          loading: false,
          error: 'Failed to fetch user data'
        });
      }
    };

    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): UserContextData {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
} 