import React, { useState, useRef, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { FileUploadProps } from '../../types/props';
import { useOrganization } from '../../contexts/OrganizationContext';
import Select from 'react-select';

interface UploadTarget {
  id: string;
  name: string;
  type: 'organization' | 'department';
}

interface UserOption {
  value: string;
  label: string;
  department?: string;
  role?: string;
}

export default function FileUpload({ onUploadSuccess, setToast }: FileUploadProps) {
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploadTargets, setUploadTargets] = useState<UploadTarget[]>([]);
  const [selectedTargetId, setSelectedTargetId] = useState('');
  const [selectedTargetType, setSelectedTargetType] = useState<'organization' | 'department' | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentContext } = useOrganization();

  useEffect(() => {
    const fetchUploadTargets = async () => {
      try {
        const response = await axios.get('/api/documents/upload-targets');
        setUploadTargets(response.data.targets);
      } catch (error) {
        console.error('Error fetching upload targets:', error);
        setToast({
          message: 'Failed to fetch upload targets',
          type: 'error'
        });
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/documents/organization-users');
        const users = response.data.users.map((user: any) => ({
          value: user.id,
          label: user.name,
          department: user.department?.name || 'No Department',
          role: user.role
        }));
        setUserOptions(users);
      } catch (error) {
        console.error('Error fetching users:', error);
        setUserOptions([]);
      }
    };
    
    // Reset all state when context changes
    setSelectedTargetId('');
    setSelectedTargetType(null);
    setSelectedUser(null);
    setUserOptions([]);
    setUploadTargets([]);
    setFiles(null);
    
    if (currentContext.organization_id) {
      fetchUploadTargets();
      fetchUsers();
    }
  }, [currentContext.organization_id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!files || files.length === 0) return;

    setIsLoading(true);
    const formData = new FormData();
    
    // Add all files to formData
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    
    if (selectedUser) {
      formData.append('target_type', 'user');
      formData.append('target_id', selectedUser.value);
    } else if (selectedTargetId && selectedTargetType) {
      formData.append('target_type', selectedTargetType);
      formData.append('target_id', selectedTargetId);
    }

    try {
      const response = await axios.post('/api/documents/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const uploadedFiles = response.data.files;
      const hasErrors = uploadedFiles.some((file: any) => file.error);
      
      if (hasErrors) {
        const errorMessages = uploadedFiles
          .filter((file: any) => file.error)
          .map((file: any) => `${file.name}: ${file.error}`)
          .join(', ');
        
        setToast({
          message: `Some files failed to upload: ${errorMessages}`,
          type: 'error'
        });
      } else {
        setToast({
          message: 'All files uploaded successfully',
          type: 'success'
        });
      }
      
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setFiles(null);
      setSelectedTargetId('');
      setSelectedTargetType(null);
      setSelectedUser(null);
      onUploadSuccess();
    } catch (err) {
      const error = err as AxiosError<{ error: string }>;
      setToast({
        message: error.response?.data?.error || 'Failed to upload files',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFiles(e.target.files);
    } else {
      setFiles(null);
    }
  };

  const handleTargetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (!value) {
      setSelectedTargetId('');
      setSelectedTargetType(null);
      return;
    }

    const [type, id] = value.split(':');
    setSelectedTargetType(type as 'organization' | 'department');
    setSelectedTargetId(id);
    setSelectedUser(null);
  };

  const handleUserChange = (option: UserOption | null) => {
    setSelectedUser(option);
    if (option) {
      setSelectedTargetId('');
      setSelectedTargetType(null);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Upload Documents</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="file" className="block text-sm font-medium text-gray-700">
            Select Files
          </label>
          <input
            type="file"
            id="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="mt-1 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-indigo-50 file:text-indigo-700
              hover:file:bg-indigo-100"
            required
            multiple
          />
        </div>

        {currentContext.organization_id && (
          <>
            <div className="mb-4">
              <label htmlFor="target" className="block text-sm font-medium text-gray-700 mb-1">
                Upload to Department/Organization
              </label>
              <select
                id="target"
                value={selectedTargetType && selectedTargetId ? `${selectedTargetType}:${selectedTargetId}` : ''}
                onChange={handleTargetChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                  bg-white py-2 px-3"
                disabled={!!selectedUser}
              >
                <option value="">Upload as private document</option>
                {uploadTargets.map(target => (
                  <option key={`${target.type}:${target.id}`} value={`${target.type}:${target.id}`}>
                    {target.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="user" className="block text-sm font-medium text-gray-700 mb-1">
                Or Upload for Specific User
              </label>
              <Select
                id="user"
                value={selectedUser}
                onChange={handleUserChange}
                options={userOptions}
                isDisabled={!!(selectedTargetType && selectedTargetId)}
                isClearable
                className="text-sm"
                placeholder="Search for a user..."
                formatOptionLabel={({ label, department, role }) => (
                  <div>
                    <div>{label}</div>
                    <div className="text-xs text-gray-500">
                      {department} • {role}
                    </div>
                  </div>
                )}
              />
            </div>
          </>
        )}

        <button
          type="submit"
          disabled={isLoading || !files}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-400"
        >
          {isLoading ? 'Uploading...' : 'Upload Files'}
        </button>
      </form>
    </div>
  );
}