import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { CalendarEvent } from '../../types';
import SyncCalendarForm from './SyncCalendarForm';
import Toast from '../common/Toast';
import Progress from '../common/Progress';
import Modal from '../common/Modal';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';

export interface CalendarEventsProps {
  onEventsUpdated?: () => void;
}

interface ModalState {
  isOpen: boolean;
  title: string;
  content: string;
  onConfirm?: () => void;
  isConfirmation: boolean;
}

interface ToastState {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
}

interface TaggedEvents {
  [key: string]: CalendarEvent[];
}

export default function CalendarEvents({ onEventsUpdated }: CalendarEventsProps) {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [tagEvents, setTagEvents] = useState<TaggedEvents>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [toast, setToast] = useState<ToastState | null>(null);
  const [progress, setProgress] = useState({ show: false, message: '', value: 0 });
  const [modal, setModal] = useState<ModalState>({
    isOpen: false,
    title: '',
    content: '',
    onConfirm: undefined,
    isConfirmation: false
  });

  useEffect(() => {
    fetchEvents();
  }, [searchQuery]);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await axios.get<CalendarEvent[]>('/api/calendar/events');
      setEvents(response.data);
      organizeEventsByTag(response.data);
      onEventsUpdated?.();
    } catch (err) {
      const axiosError = err as AxiosError;
      setError(axiosError.message || 'Failed to fetch calendar events');
    } finally {
      setLoading(false);
    }
  };

  const organizeEventsByTag = (events: CalendarEvent[]) => {
    const tagged: TaggedEvents = {};
    events.forEach(event => {
      const tags = event.summary.match(/#\w+/g) || ['#untagged'];
      tags.forEach(tag => {
        if (!tagged[tag]) {
          tagged[tag] = [];
        }
        tagged[tag].push(event);
      });
    });
    setTagEvents(tagged);
  };

  const showToast = (message: string, type: ToastState['type']) => {
    setToast({ message, type });
  };

  const showConfirmModal = (title: string, content: string, onConfirm?: () => void) => {
    setModal({ 
      isOpen: true, 
      title, 
      content, 
      onConfirm,
      isConfirmation: true 
    });
  };

  const handleConfirm = () => {
    if (modal.onConfirm) {
      modal.onConfirm();
    }
    closeModal();
  };

  const handleDeleteAll = async () => {
    showConfirmModal(
      'Delete All Events',
      'Are you sure you want to delete all events? This action cannot be undone.',
      async () => {
        setProgress({ show: true, message: 'Deleting all events...', value: 0 });
        try {
          const response = await axios.delete('/api/calendar/events/all');
          setProgress({ show: true, message: 'Deleting all events...', value: 100 });
          setTimeout(() => setProgress({ show: false, message: '', value: 0 }), 500);
          setEvents([]);
          setTagEvents({});
          showToast(`Successfully deleted ${response.data.count} events`, 'success');
          onEventsUpdated?.();
        } catch (error) {
          showToast('Failed to delete all events', 'error');
        }
      }
    );
  };

  const handleDeleteTag = async (tag: string) => {
    showConfirmModal(
      'Delete Tag Events',
      `Are you sure you want to delete all events with tag "${tag}"? This action cannot be undone.`,
      async () => {
        setProgress({ show: true, message: `Deleting events with tag "${tag}"...`, value: 0 });
        try {
          const response = await axios.delete(`/api/calendar/events/tag/${tag}`);
          setProgress({ show: true, message: `Deleting events with tag "${tag}"...`, value: 100 });
          setTimeout(() => setProgress({ show: false, message: '', value: 0 }), 500);
          const updatedTagEvents = { ...tagEvents };
          delete updatedTagEvents[tag];
          setTagEvents(updatedTagEvents);
          showToast(`Successfully deleted ${response.data.count} events with tag "${tag}"`, 'success');
          onEventsUpdated?.();
        } catch (error) {
          showToast(`Failed to delete events with tag "${tag}"`, 'error');
        }
      }
    );
  };

  const handleSync = async (syncOptions: { filter_hashtag: string; sync_future_events: boolean }) => {
    setProgress({ show: true, message: 'Starting calendar sync...', value: 0 });
    try {
      const response = await axios.post('/api/calendar/sync', syncOptions);
      
      // Show final success message
      setProgress({ 
        show: true, 
        message: `Successfully synced ${response.data.events_count} events`, 
        value: 100 
      });
      
      // Hide progress after a delay
      setTimeout(() => setProgress({ show: false, message: '', value: 0 }), 2000);
      
      // Refresh events list
      fetchEvents();
      
      showToast(`Successfully synced ${response.data.events_count} events`, 'success');
      onEventsUpdated?.();
    } catch (error) {
      setProgress({ show: false, message: '', value: 0 });
      const axiosError = error as AxiosError<{ error: string }>;
      showToast('Failed to sync calendar: ' + (axiosError.response?.data?.error || 'Unknown error'), 'error');
    }
  };

  const handleDelete = async (eventId: string) => {
    try {
      await axios.delete(`/api/calendar/events/${eventId}`);
      
      // Update local state
      const updatedEvents = events.filter(event => event.id !== eventId);
      setEvents(updatedEvents);
      
      // Update tag events
      const updatedTagEvents = { ...tagEvents };
      Object.keys(tagEvents).forEach(tag => {
        updatedTagEvents[tag] = tagEvents[tag].filter(event => event.id !== eventId);
        if (updatedTagEvents[tag].length === 0) {
          delete updatedTagEvents[tag];
        }
      });
      setTagEvents(updatedTagEvents);
      
      showToast('Event deleted successfully', 'success');
      onEventsUpdated?.();
    } catch (error) {
      const axiosError = error as AxiosError<{ error: string }>;
      showToast('Failed to delete event: ' + (axiosError.response?.data?.error || 'Unknown error'), 'error');
    }
  };

  const showEventDetails = (event: CalendarEvent) => {
    setModal({
      isOpen: true,
      title: event.summary,
      content: `Start: ${new Date(event.start_time).toLocaleString()}\nEnd: ${new Date(event.end_time).toLocaleString()}\n${event.description || ''}`,
      onConfirm: undefined,
      isConfirmation: false
    });
  };

  const closeModal = () => {
    setModal({
      isOpen: false,
      title: '',
      content: '',
      onConfirm: undefined,
      isConfirmation: false
    });
  };

  if (loading) {
    return <div className="text-center p-4">Loading calendar events...</div>;
  }

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold mb-8">Calendar Events</h1>
      <SyncCalendarForm onSync={handleSync} />
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search events..."
        className="w-full p-2 mb-4 border rounded"
        hidden={Object.keys(tagEvents).length === 0}
      />
      <div className="mb-4" hidden={Object.keys(tagEvents).length === 0}>
        <button
          onClick={handleDeleteAll}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-2"
        >
          Delete All Events
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.entries(tagEvents).map(([tag, events]) => (
          <div key={tag} className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4">{tag}</h3>
            <div className="space-y-2">
              {events.map(event => (
                <div
                  key={event.id}
                  className="p-2 bg-gray-50 rounded cursor-pointer hover:bg-gray-100"
                  onClick={() => showEventDetails(event)}
                >
                  <div className="font-medium">{event.summary}</div>
                  <div className="text-sm text-gray-500">
                    {new Date(event.start_time).toLocaleString()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {modal.isOpen && (
        <Modal
          isOpen={modal.isOpen}
          title={modal.title}
          onClose={closeModal}
        >
          <div className="whitespace-pre-wrap">{modal.content}</div>
        </Modal>
      )}

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      {progress.show && (
        <Progress
          message={progress.message}
          progress={progress.value}
        />
      )}
    </div>
  );
}