import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { DataDisplayProps } from '../../types/props';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DataDisplay({ chartLabels, totalHours, eventsHourData, dateSelectionString }: DataDisplayProps) {
  const allocatedHours = eventsHourData.reduce((sum, hours) => sum + hours, 0);
  const unallocatedHours = totalHours - allocatedHours;

  const data = {
    labels: [...chartLabels, 'Unallocated'],
    datasets: [
      {
        data: [...eventsHourData, unallocatedHours],
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E7E9ED'
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E7E9ED'
        ]
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right' as const
      },
      title: {
        display: true,
        text: `Time Allocation for ${dateSelectionString}`
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.label || '';
            const value = context.raw || 0;
            const percentage = ((value / totalHours) * 100).toFixed(1);
            return `${label}: ${value} hours (${percentage}%)`;
          }
        }
      }
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Time Analysis</h2>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-2/3">
          <Pie data={data} options={options} />
        </div>
        <div className="w-full md:w-1/3 mt-4 md:mt-0 md:ml-8">
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-medium">Total Hours</h3>
              <p className="text-2xl font-bold">{totalHours}</p>
            </div>
            <div>
              <h3 className="text-lg font-medium">Allocated Hours</h3>
              <p className="text-2xl font-bold">{allocatedHours}</p>
            </div>
            <div>
              <h3 className="text-lg font-medium">Unallocated Hours</h3>
              <p className="text-2xl font-bold">{unallocatedHours}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}