import React, { useState, FormEvent, ChangeEvent } from 'react';
import { SearchOptionsProps } from '../../types/props';

const SearchOptions: React.FC<SearchOptionsProps> = ({ onSearch }) => {
  const [searchType, setSearchType] = useState<'summary' | 'description'>('summary');
  const [searchQuery, setSearchQuery] = useState('');
  const [useRegex, setUseRegex] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch({ searchQuery, searchType, useRegex });
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
        <select
          value={searchType}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => setSearchType(e.target.value as 'summary' | 'description')}
          className="border rounded px-2 py-1 w-full md:w-auto"
        >
          <option value="summary">Summary</option>
          <option value="description">Description</option>
        </select>
        <input
          type="text"
          value={searchQuery}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          placeholder="Search events..."
          className="border rounded px-2 py-1 w-full md:flex-grow"
        />
        <div className="flex items-center space-x-2 justify-between md:justify-start">
          <label className="flex items-center whitespace-nowrap">
            <input
              type="checkbox"
              checked={useRegex}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setUseRegex(e.target.checked)}
              className="mr-2"
            />
            Use Regex
            <span className="ml-1 text-gray-500 cursor-pointer group relative">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
              <span className="absolute w-48 bg-black text-white text-xs rounded py-1 px-2 bottom-full mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-1/2 transform -translate-x-1/2 whitespace-normal break-words">
                Regex (Regular Expression) is a powerful pattern matching tool. Learn more and test your regex at <a href="https://regex101.com" target="_blank" rel="noopener noreferrer" className="underline">regex101.com</a>
              </span>
            </span>
          </label>
          <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded w-full md:w-auto">
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchOptions;