import React from 'react';
import { EventsDisplayProps } from '../../types/props';
import { CalendarEvent } from '../../types/contexts';

interface RangeResult {
  topEvents: [string, number][];
  remainingHours: number;
  remainingCount: number;
}

const EventsDisplay: React.FC<EventsDisplayProps> = ({ tags, tagKeys, dateFilterHours, dateSelectionString }) => {
  const createRange = (events: CalendarEvent[]): RangeResult => {
    // Sort events by duration in descending order
    const sortedEvents = [...events].sort((a, b) => b.duration - a.duration);
    return {
      topEvents: sortedEvents.slice(0, 5).map(event => [event.summary, event.duration]),
      remainingHours: sortedEvents.slice(5).reduce((sum, event) => sum + event.duration, 0),
      remainingCount: Math.max(0, sortedEvents.length - 5)
    };
  };

  const calculatePercentage = (hours: number, totalHours: number): string => {
    if (totalHours === 0) return '0.00';
    return ((hours / totalHours) * 100).toFixed(2);
  };

  // Sort tagKeys by total hours in descending order
  const sortedTagKeys = tagKeys.sort((a, b) => {
    const totalA = tags[a].reduce((sum, event) => sum + event.duration, 0);
    const totalB = tags[b].reduce((sum, event) => sum + event.duration, 0);
    return totalB - totalA;
  });

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">{dateSelectionString}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {sortedTagKeys.map(eventKey => {
          if (tags[eventKey].length > 0) {
            const totalHours = tags[eventKey].reduce((sum, event) => sum + event.duration, 0);
            const { topEvents, remainingHours, remainingCount } = createRange(tags[eventKey]);
            
            return (
              <div key={eventKey} className="bg-white shadow rounded-lg p-4">
                <h3 className="text-xl font-semibold mb-2">{eventKey}</h3>
                <h4 className="text-md text-gray-600 mb-2">
                  Top {Math.min(tags[eventKey].length, 5)} events by hours:
                  <span className="float-right">Hours</span>
                </h4>
                <ul className="mb-4">
                  {topEvents.map(([summary, duration], index) => (
                    <li key={index} className="flex justify-between">
                      <span>{summary}</span>
                      <span>{duration.toFixed(2)}</span>
                    </li>
                  ))}
                  {remainingCount > 0 && (
                    <li className="flex justify-between text-gray-500 mt-2 pt-2 border-t">
                      <span>{remainingCount} other events</span>
                      <span>{remainingHours.toFixed(2)}</span>
                    </li>
                  )}
                </ul>
                <p className="text-sm text-gray-600">Total hours: {totalHours.toFixed(2)}</p>
                <p className="text-sm text-gray-600">
                  Percentage of time invested: {calculatePercentage(totalHours, dateFilterHours)}%
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default EventsDisplay;