import React, { useState } from 'react';
import Query from '../chat/Query';
import Results from '../chat/Results';

export default function Dashboard() {
  const [queryResult, setQueryResult] = useState('');

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">AI Coaching Dashboard</h1>
      <div className="grid gap-8">
        <Query setResult={setQueryResult} />
        {queryResult && <Results result={queryResult} />}
      </div>
    </div>
  );
}