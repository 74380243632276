import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { OrganizationProviderProps } from '../types/contexts';

export interface OrganizationContextData {
  isMember: boolean;
  orgId: string | null;
  role: string | null;
  isManager: boolean;
  isAdmin: boolean;
  data: any;
  loading: boolean;
  organizations: Array<{
    id: string;
    name: string;
    role: string;
  }>;
  currentContext: {
    organization_id: string | null;
    organization_name: string | null;
    department_id: string | null;
    department_name: string | null;
  };
  setOrganizationContext: (orgId: string | null) => Promise<void>;
}

// Create a custom event for organization context changes
export const ORG_CONTEXT_CHANGED_EVENT = 'organizationContextChanged';

export const emitOrgContextChanged = () => {
  const event = new CustomEvent(ORG_CONTEXT_CHANGED_EVENT, {
    detail: { timestamp: Date.now() }
  });
  window.dispatchEvent(event);
};

const initialOrgData: OrganizationContextData = {
  isMember: false,
  orgId: null,
  role: null,
  isManager: false,
  isAdmin: false,
  data: null,
  loading: true,
  organizations: [],
  currentContext: {
    organization_id: null,
    organization_name: null,
    department_id: null,
    department_name: null
  },
  setOrganizationContext: async () => {
    throw new Error('Organization context not initialized');
  }
};

const OrganizationContext = createContext<OrganizationContextData>(initialOrgData);

export function OrganizationProvider({ children }: OrganizationProviderProps) {
  const [organizationData, setOrganizationData] = useState<OrganizationContextData>(initialOrgData);

  const fetchOrgData = async () => {
    try {
      const [orgStatus, orgManager, orgAdmin, orgs, context] = await Promise.all([
        axios.get('/api/user/organization-status'),
        axios.get('/api/user/is-org-manager'),
        axios.get('/api/user/is-org-admin'),
        axios.get('/api/user/organizations'),
        axios.get('/api/user/current-context')
      ]);

      setOrganizationData({
        isMember: orgStatus.data.isMember,
        orgId: orgStatus.data.data?.organization_id || null,
        role: orgStatus.data.data?.role || null,
        isManager: orgManager.data.isOrgManager,
        isAdmin: orgAdmin.data.isOrgAdmin,
        data: orgStatus.data.data || null,
        loading: false,
        organizations: orgs.data.organizations,
        currentContext: context.data,
        setOrganizationContext: setOrgContext
      });
    } catch (error) {
      console.error('Error fetching organization data:', error);
      setOrganizationData(prev => ({ ...prev, loading: false }));
    }
  };

  const setOrgContext = async (orgId: string | null) => {
    try {
      await axios.post('/api/user/set-organization-context', {
        organization_id: orgId
      });
      
      // Refresh the data
      await fetchOrgData();
      
      // Emit the context changed event
      emitOrgContextChanged();
    } catch (error) {
      console.error('Error setting organization context:', error);
    }
  };

  useEffect(() => {
    fetchOrgData();
  }, []);

  return (
    <OrganizationContext.Provider value={organizationData}>
      {children}
    </OrganizationContext.Provider>
  );
}

export const useOrganization = () => useContext(OrganizationContext); 