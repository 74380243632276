import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { Department, Organization } from '../../types';
import CreateDepartment from './CreateDepartment';

interface DepartmentListProps {
  organization: Organization | null;
  departments: Department[];
  onDepartmentSelect?: (departmentId: string) => void;
  showCreateButton?: boolean;
}

interface DepartmentNode extends Department {
  level: number;
  children: DepartmentNode[];
}

export default function DepartmentList({
  organization,
  departments,
  onDepartmentSelect,
  showCreateButton = true
}: DepartmentListProps) {
  const [departmentTree, setDepartmentTree] = useState<DepartmentNode[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (departments.length > 0) {
      const tree = departments.map(dept => ({
        ...dept,
        level: 0,
        children: dept.sub_departments?.map(subDept => ({
          ...subDept,
          level: 1,
          children: subDept.sub_departments?.map(subSubDept => ({
            ...subSubDept,
            level: 2,
            children: []
          })) || []
        })) || []
      }));
      setDepartmentTree(tree);
    }
  }, [departments]);

  const handleDepartmentClick = (departmentId: string) => {
    if (onDepartmentSelect) {
      onDepartmentSelect(departmentId);
    }
  };

  const renderDepartment = (department: DepartmentNode) => {
    const paddingLeft = `${department.level * 20}px`;

    return (
      <div key={department.id}>
        <div
          className={`p-3 hover:bg-gray-100 cursor-pointer flex items-center ${
            department.level > 0 ? 'border-l' : ''
          }`}
          style={{ paddingLeft }}
          onClick={() => handleDepartmentClick(department.id)}
        >
          <span className="flex-grow">{department.name}</span>
        </div>
        {department.children.map(child => renderDepartment(child))}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b">
        <h2 className="text-xl font-semibold">Departments</h2>
      </div>

      <div className="p-4">
        {error && (
          <div className="text-red-500 mb-4">
            {error}
          </div>
        )}

        {isLoading ? (
          <div className="text-center py-4">Loading departments...</div>
        ) : departmentTree.length > 0 ? (
          <div className="border rounded">
            {departmentTree.map(department => renderDepartment(department))}
          </div>
        ) : (
          <div className="text-center py-4 text-gray-500">
            No departments found
          </div>
        )}

        {showCreateButton && organization && (
          <div className="mt-4">
            <CreateDepartment
              organizationId={organization.id}
              onDepartmentCreated={() => {
                // Refresh departments
              }}
            />
          </div>
        )}
      </div>

      <style>
        {`
          .department-tree {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .department-item {
            padding: 8px 16px;
            border-bottom: 1px solid #e5e7eb;
          }
          .department-item:last-child {
            border-bottom: none;
          }
        `}
      </style>
    </div>
  );
}