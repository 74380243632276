import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import DateSelector from '../components/life-roi/DateSelector';
import SearchOptions from '../components/life-roi/SearchOptions';
import EventsDisplay from '../components/life-roi/EventsDisplay';
import DataDisplay from '../components/life-roi/DataDisplay';
import TimeAnalysisChat from '../components/life-roi/TimeAnalysisChat';
import { CalendarEvent } from '../types/models';

interface SearchOptions {
  searchQuery?: string;
  searchType?: 'tag' | 'title';
  useRegex?: boolean;
}

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface TaggedEvents {
  [key: string]: CalendarEvent[];
}

interface HoursByTag {
  [key: string]: number;
}

function LifeROI() {
  const [tags, setTags] = useState({});
  const [keys, setKeys] = useState([]);
  const [dateFilterHours, setDateFilterHours] = useState(0);
  const [dateSelectionString, setDateSelectionString] = useState('');
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({});
  const [historicalData, setHistoricalData] = useState(null);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31)
  });
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((new Date().getMonth() / 3)) + 1;
    getTags({period: 'quarter', start: currentYear.toString(), end: currentQuarter.toString(), dateSelectionString: `${currentYear} Q${currentQuarter}`});
    collectHistoricalData();
  }, []);


  const getTags = async (dateInfo) => {
    try {
      let url = `/api/calendar/events?period=${dateInfo.period}`;
      if (dateInfo.period === 'custom') {
        url += `&start=${dateInfo.start}&end=${dateInfo.end}`;
      } else {
        url += `&year=${dateInfo.start}`;
        if (dateInfo.period === 'month' || dateInfo.period === 'quarter') {
          url += `&${dateInfo.period}=${dateInfo.end}`;
        }
      }



      if (searchOptions.searchQuery) {
        url += `&searchType=${searchOptions.searchType}&searchQuery=${encodeURIComponent(searchOptions.searchQuery)}`;
        if (searchOptions.useRegex) {
          url += '&useRegex=true';
        }
      }
      const response = await axios.get(url);
      const formatData = response.data;
      setTags(formatData);
      setKeys(Object.keys(formatData));
      console.log(dateInfo);
      setDateFilterHours(calculateDateFilterHours(dateInfo.start, dateInfo.end, dateInfo.period));
      setDateSelectionString(dateInfo.dateSelectionString);
      updateChartData(formatData);
      setIsLoading(false);

    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const calculateDateFilterHours = (start, end, period) => {
    if (period === 'year') {
      return 365 * 24; // Approximate hours in a year
    } else if (period === 'quarter') {
      return 91 * 24; // Approximate hours in a quarter
    } else if (period === 'month') {
      return 30 * 24; // Approximate hours in a month
    } else if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffInMilliseconds = Number(endDate) - Number(startDate);
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      return Math.round(diffInHours);
    }
    return 0; // Default value when start and end are not provided
  };

  const updateChartData = (data) => {
    const labels = Object.keys(data);
  
    const chartData = labels.map(label => 
      data[label].reduce((sum, event) => sum + event.duration, 0)
    );
  
    setChartLabels(labels);
    setChartData(chartData);
  };

  const getEventsByTag = () => {
    const tagEvents: TaggedEvents = {};
    console.log(events);
    const filtered = (events as CalendarEvent[]).filter(event => {
      if (!event.tag) return false;
      if (!searchOptions.searchQuery) return true;
      if (searchOptions.searchType === 'tag' && event.tag) {
        return searchOptions.useRegex
          ? new RegExp(searchOptions.searchQuery || '').test(event.tag)
          : event.tag.includes(searchOptions.searchQuery || '');
      }
      return searchOptions.useRegex
        ? new RegExp(searchOptions.searchQuery || '').test(event.title)
        : event.title.includes(searchOptions.searchQuery || '');
    });

    filtered.forEach(event => {
      if (event.tag) {
        if (!tagEvents[event.tag]) {
          tagEvents[event.tag] = [];
        }
        tagEvents[event.tag].push(event);
      }
    });

    return tagEvents;
  };

  const handleSearch = (options) => {
    setSearchOptions(options);
    // Parse the dateSelectionString to get the correct period information
    const parts = dateSelectionString.split(' ');
    let dateInfo;
    if (parts.length === 2 && parts[1].startsWith('Q')) {
      // Quarter format: "2024 Q4"
      dateInfo = ['quarter', parts[0], parts[1].substring(1), dateSelectionString];
    } else if (parts.length === 2) {
      // Month format: "2024 01"
      dateInfo = ['month', parts[0], parts[1], dateSelectionString];
    } else {
      // Year format: "2024"
      dateInfo = ['year', parts[0], '', dateSelectionString];
    }
    getTags(dateInfo);
  };

  const collectHistoricalData = async () => {
    try {
      // Get current date for the end date
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = '2005-01-01'; // Starting from 2005
  
      // Get all events in one request
      const url = `/api/calendar/events?period=custom&start=${startDate}&end=${endDate}`;
      const response = await axios.get(url);
      const allEvents = response.data;
  
      const historicalData = {
        years: {},
        quarters: {},
        months: {}
      };
  
      // Process years
      const years = [...new Set(Object.values(allEvents)
        .flat()
        .map((event: any) => new Date(event.start_time).getFullYear()))].sort();
  
      years.forEach(year => {
        const yearEvents = filterEventsByTimeframe(allEvents, 
          `${year}-01-01`, 
          `${year}-12-31`
        );
        historicalData.years[year] = processTimeframeData(yearEvents, 365 * 24);
      });
  
      // Process quarters
      years.forEach(year => {
        [1, 2, 3, 4].forEach(quarter => {
          const startMonth = (quarter - 1) * 3 + 1;
          const endMonth = quarter * 3;
          const quarterEvents = filterEventsByTimeframe(allEvents,
            `${year}-${String(startMonth).padStart(2, '0')}-01`,
            `${year}-${String(endMonth).padStart(2, '0')}-${endMonth === 3 || endMonth === 12 ? '31' : '30'}`
          );
          historicalData.quarters[`${year}-Q${quarter}`] = processTimeframeData(quarterEvents, 91 * 24);
        });
      });
  
      // Process months
      years.forEach(year => {
        for (let month = 1; month <= 12; month++) {
          const monthStr = String(month).padStart(2, '0');
          const daysInMonth = new Date(year, month, 0).getDate();
          const monthEvents = filterEventsByTimeframe(allEvents,
            `${year}-${monthStr}-01`,
            `${year}-${monthStr}-${daysInMonth}`
          );
          historicalData.months[`${year}-${monthStr}`] = processTimeframeData(monthEvents, daysInMonth * 24);
        }
      });
  
      // Save to database
      await axios.post('/api/calendar/historical-data', { historicalData });
      setHistoricalData(historicalData);
      return historicalData;
    } catch (error) {
      console.error('Error collecting historical data:', error);
    }
  };
  
  const filterEventsByTimeframe = (events, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const filteredEvents = {};
    Object.entries(events).forEach(([tag, tagEvents]) => {
      console.log(tagEvents);
      const filtered = (tagEvents as CalendarEvent[]).filter(event => {
        const eventStart = new Date(event.start_time);
        return eventStart >= start && eventStart <= end;

      });

      if (filtered.length > 0) {
        filteredEvents[tag] = filtered;
      }
    });
    

    return filteredEvents;
  };
  
  const processTimeframeData = (events, totalHours) => {
    const tagTotals = {};
    let allocatedHours = 0;
  
    // Calculate total hours for each tag
    Object.entries(events).forEach(([tag, tagEvents]) => {
      const total = (tagEvents as CalendarEvent[]).reduce((sum, event) => sum + event.duration, 0);
      tagTotals[tag] = total;
      allocatedHours += total;
    });

  
    // Convert to array, sort by total hours, and convert back to object
    const sortedEntries = Object.entries(tagTotals)
      .sort(([, a], [, b]) => Number(b) - Number(a)); // Sort in descending order
  
    // Create new sorted objects
    const sortedTags = {};
    const sortedEvents = {};
  
    sortedEntries.forEach(([tag, total]) => {
      sortedTags[tag] = total;
      sortedEvents[tag] = events[tag];
    });
  
    return {
      tagTotals: sortedTags,
      allocatedHours,
      unallocatedHours: Math.max(0, totalHours - allocatedHours),
      totalHours,
      events: sortedEvents
    };
  };

  if (isLoading) {
    return <div className="text-center p-4">Loading events...</div>;
  }

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Investment Dashboard</h1>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-2/3 w-full">
          <DateSelector onDateChosen={getTags} />
          <SearchOptions onSearch={handleSearch} />
          <EventsDisplay tags={tags} tagKeys={keys} dateFilterHours={dateFilterHours} dateSelectionString={dateSelectionString} />
          <DataDisplay chartLabels={chartLabels} totalHours={dateFilterHours} eventsHourData={chartData} dateSelectionString={dateSelectionString} />
        </div>
        {historicalData && <TimeAnalysisChat historicalData={historicalData} />}
      </div>
  </div>
);
}

export default LifeROI;