import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import DepartmentList from '../components/admin/DepartmentList';
import { Department, Organization } from '../types';
import { useOrganization } from '../contexts/OrganizationContext';

export default function DepartmentManagementPage() {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { currentContext } = useOrganization();

  useEffect(() => {
    const fetchData = async () => {
      if (!currentContext.organization_id) {
        setError('No organization selected');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get<Department[]>(`/api/organizations/${currentContext.organization_id}/departments`);
        setDepartments(response.data);
      } catch (err) {
        const axiosError = err as AxiosError;
        setError(axiosError.message || 'Failed to fetch data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentContext.organization_id]);

  if (isLoading) {
    return <div className="text-center p-4">Loading departments...</div>;
  }

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (!currentContext.organization_id) {
    return <div className="text-center p-4">Please select an organization to manage departments.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Department Management</h1>
      <DepartmentList 
        organization={{ 
          id: currentContext.organization_id, 
          name: currentContext.organization_name || '' 
        }} 
        departments={departments} 
      />
    </div>
  );
} 