import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import UserList from '../components/admin/UserList';
import { Department } from '../types/models';

export default function UserManagementPage() {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get<Department[]>('/api/departments');
        setDepartments(response.data);
        if (response.data.length > 0) {
          setSelectedDepartmentId(response.data[0].id);
        }
      } catch (err) {
        const axiosError = err as AxiosError;
        setError(axiosError.message || 'Failed to fetch departments');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  if (isLoading) {
    return <div className="text-center p-4">Loading departments...</div>;
  }

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (departments.length === 0) {
    return <div className="text-center p-4">No departments found. Please create a department first.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">User Management</h1>
      
      <div className="mb-6">
        <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-2">
          Select Department
        </label>
        <select
          id="department"
          value={selectedDepartmentId || ''}
          onChange={(e) => setSelectedDepartmentId(Number(e.target.value))}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          {departments.map((dept) => (
            <option key={dept.id} value={dept.id}>
              {dept.name}
            </option>
          ))}
        </select>
      </div>

      {selectedDepartmentId && <UserList departmentId={selectedDepartmentId} />}
    </div>
  );
}