import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useOrganization } from '../../contexts/OrganizationContext';
import { Department } from '../../types';

interface CreateDepartmentProps {
  onDepartmentCreated: () => void;
  organizationId: string;
}

interface FlatDepartment {
  id: string;
  name: string;
  level: number;
}

export default function CreateDepartment({ onDepartmentCreated, organizationId }: CreateDepartmentProps) {
  const [name, setName] = useState('');
  const [parentDepartmentId, setParentDepartmentId] = useState('');
  const [departments, setDepartments] = useState<FlatDepartment[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentContext } = useOrganization();

  useEffect(() => {
    if (!currentContext.organization_id) return;

    const fetchDepartments = async () => {
      try {
        const response = await axios.get<Department[]>(`/api/organizations/${currentContext.organization_id}/departments`);
        setDepartments(flattenDepartments(response.data));
      } catch (err) {
        console.error('Error fetching departments:', err);
      }
    };

    fetchDepartments();
  }, [currentContext.organization_id]);

  // Helper function to flatten the department tree for the select options
  const flattenDepartments = (deptTree: Department[], level = 0, prefix = ''): FlatDepartment[] => {
    let flatList: FlatDepartment[] = [];
    deptTree.forEach(dept => {
      flatList.push({ 
        id: dept.id, 
        name: prefix + dept.name,
        level
      });
      if (dept.sub_departments && dept.sub_departments.length > 0) {
        flatList = flatList.concat(flattenDepartments(dept.sub_departments, level + 1, prefix + '--'));
      }
    });
    return flatList;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await axios.post(`/api/organizations/${currentContext.organization_id}/departments`, {
        name,
        parent_department_id: parentDepartmentId || null
      });
      
      setName('');
      setParentDepartmentId('');
      onDepartmentCreated();
    } catch (err) {
      const error = err as AxiosError<{ error: string }>;
      setError(error.response?.data?.error || 'Failed to create department.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Create New Department</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Department Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="parentDepartment" className="block text-sm font-medium text-gray-700">
            Parent Department (Optional)
          </label>
          <select
            id="parentDepartment"
            value={parentDepartmentId}
            onChange={(e) => setParentDepartmentId(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">No Parent Department</option>
            {departments.map(dept => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </select>
        </div>

        {error && (
          <div className="mb-4 text-red-500 text-sm">
            {error}
          </div>
        )}
        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-400"
        >
          {isLoading ? 'Creating...' : 'Create Department'}
        </button>
      </form>
    </div>
  );
} 